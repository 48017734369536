import React, { useCallback, useEffect, useState } from 'react';

import InvalidTransactionScreen from './components/InvalidTransactionScreen';
import AppLoader from './components/AppLoader';
import { decryptAppCredentials } from './util';

const API_URL = process.env.REACT_APP_API_URL;

interface AppCredentials {
  appId: string;
  publicKey: string;
  apiKey: string;
  payLinkId: string;
  email: string;
  successRedirectUri?: string;
  failureRedirectUri?: string;
  amount: number;
  extraDetails: any;
}

function App() {
  const { pathname } = window.location;
  const [appCredentials, setAppCredentials] = useState<
    AppCredentials | undefined
  >();
  const [transactionInitiated, setTransactionInitiated] = useState(false);
  const [token, setToken] = useState<string | undefined>(
    pathname.replace(/\//, ''),
  );

  const [isLoading, setIsLoading] = useState(Boolean(token));

  const fetchAppCredentials = useCallback(async () => {
    if (!token) return;
    const res = await fetch(`${API_URL}/credentials/${token}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application' },
    });
    const credentials = await res.json();
    if (res.status == 400) setToken(undefined);
    else {
      setAppCredentials(decryptAppCredentials(credentials) as any);
    }
    setIsLoading(false);
  }, [token]);

  useEffect(() => {
    fetchAppCredentials().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appCredentials && !transactionInitiated) {
      const {
        appId,
        publicKey,
        apiKey,
        amount,
        email,
        successRedirectUri,
        failureRedirectUri,
        extraDetails: details,
        payLinkId,
      } = appCredentials;
      setTransactionInitiated(true);
      const zinariPay = new (window as any).ZinariPay({
        appId,
        publicKey,
        apiKey,
      });
      zinariPay.initiateTransaction({
        amount,
        details,
        payLinkId,
        notificationEmailAddress: email,
        onSuccess: () => {
          if (successRedirectUri) window.location.href = successRedirectUri;
        },
        onFailure: () => {
          if (failureRedirectUri) window.location.href = failureRedirectUri;
        },
      });
    }
  }, [appCredentials, transactionInitiated]);

  if (isLoading) return <AppLoader />;
  if (!token) return <InvalidTransactionScreen />;
  return <></>;
}

export default App;
